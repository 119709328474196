<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="admins"
          :headers="headers"
          show-select
          item-key="email"
          :loading="loading"
          :provider="provider"
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <v-btn color="primary" small @click="setAdminRole">
              Assign role
            </v-btn>
            <v-separator vertical class="mx-2"></v-separator>
          </template>
          <template #item.fullname="{ item }">
            <span
              class="cursor-pointer primary--text"
              @click="openUserDialog(item.email)"
            >
              {{ item.fullname }}
            </span>
          </template>
          <template #item.roles="{ item }">
            <div v-if="item.roles && item.roles.length">
              <v-chip
                v-for="role in item.roles"
                :key="role"
                x-small
                class="cursor-pointer primary--text"
              >
                {{ role }}
              </v-chip>
            </div>

            <span v-else>Not assigned</span>
          </template>
        </ReportTable>
      </ECol>
    </ERow>
    <AssignAdminRoleDialog
      v-model="isRoleAssignementDialogOpen"
      :users="reportStore.selectedItems"
      :availableRoles="roles"
      @roles-assigned="$root.$emit('refresh-report-table')"
      @select-user="selectUser"
      @unselect-user="unselectUser"
    />
  </v-container>
</template>

<script>
import AdminHeaders from "@/components/admins/adminHeaders"
import ReportTable from "@/components/ReportTable"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/users"
import { PERMISSIONS } from "@/constants/permissions"
import AssignAdminRoleDialog from "@/components/authz/AssignAdminRoleDialog"
import { AuthzApi } from "@evercam/shared/api/authzApi"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.ACCESS_CONTROL.VIEW_ROLES],
  },
  components: {
    ReportTable,
    AssignAdminRoleDialog,
  },
  data: () => {
    return {
      loading: false,
      isDeletionFailed: false,
      headers: AdminHeaders,
      makeAdminDialog: false,
      selectedOwner: null,
      filterFields: {
        fullname: {
          component: "TextFieldSearchFilter",
        },
        email: {
          component: "TextFieldSearchFilter",
        },
        roles: {
          component: "SelectSearchFilter",
          attributes: {
            items: [],
            multiple: true,
          },
        },
      },
      isRoleAssignementDialogOpen: false,
      roles: [],
    }
  },
  computed: {
    ...mapStores(useReportStore),
    provider() {
      return AdminApi.users.getAdmins
    },
  },
  mounted() {
    this.fetchRoles()
  },
  methods: {
    async fetchRoles() {
      try {
        const rolesResponse = await AuthzApi.roles.get({ scope: "admin" })
        this.roles = rolesResponse.items
        this.filterFields.roles.attributes.items = rolesResponse.items.map(
          (role) => ({
            name: role.name,
            value: role.id,
          })
        )
      } catch (error) {
        console.error("Error fetching roles:", error)
      }
    },
    openUserDialog(email) {
      useUserStore().openDialog(email)
    },
    close() {
      this.makeAdminDialog = false
      this.selectedOwner = null
      this.$refs.addAdmin.reset()
    },
    setAdminRole() {
      this.isRoleAssignementDialogOpen = true
    },
    selectUser(user) {
      this.reportStore.selectedItems.push(user)
    },
    unselectUser(user) {
      this.reportStore.selectedItems = this.reportStore.selectedItems.filter(
        (selectedUser) => selectedUser.email !== user.email
      )
    },
  },
}
</script>
